<!--缓存表头设置数据-->
<template>
    <div class="cacheTableHeaderSet">
        <el-button v-popover:popover class="setBtn">
            <i class="iconfont iconshezhi"></i>
            <span></span>
        </el-button>
        <el-popover
            ref="popover"
            placement="bottom-start"
            title="自定义列表项"
            width="540"
            trigger="click"
            v-model="isPopover"
        >
            <div class="tablePopover">
                <div class="popoverContent">
                    <template v-for="(item, index) in tableHeader">
                        <el-checkbox
                            :key="index"
                            v-model="checkResult[item]"
                            :label="item"
                        ></el-checkbox>
                    </template>
                </div>
                <div class="el_right">
                    <el-button size="mini" @click="isPopover = false">
                        取消
                    </el-button>
                    <el-button size="mini" type="primary" @click="handleConfim">
                        确定
                    </el-button>
                </div>
            </div>
        </el-popover>
    </div>
</template>

<script>
export default {
    name: 'cache-table-header-set',
    props: {
        // 缓存唯一标识
        cacheKey: String,
        // 表头显示隐藏数据
        checkHeader: Object,
    },
    data() {
        return {
            // 本地缓存key
            cacheLocalKey: 'cacheTableHeaderSet',
            // 缓存的总数据(多个表格)
            cacheData: {},

            tableHeader: [],
            // 最终结果
            checkResult: {},

            isPopover: false,
        };
    },
    created() {
        this.initData();
    },
    methods: {
        initData() {
            const cacheDataStr = localStorage.getItem(this.cacheLocalKey);
            if (cacheDataStr) {
                this.cacheData = JSON.parse(cacheDataStr);
            }
            if (this.cacheData[this.cacheKey]) {
                // let cacheHeader = localStorage.getItem(this.cacheKey);
                const cacheHeader = this.cacheData[this.cacheKey];
                // cacheHeader = JSON.parse(cacheHeader);
                let result = {};
                // 如果长度和缓存的数据长度不一样。说明新加或者删除了列
                if (Object.keys(cacheHeader).length !== Object.keys(this.checkHeader).length) {
                    for (const key in this.checkHeader) {
                        // eslint-disable-next-line no-undefined
                        result[key] = cacheHeader[key] === false ? false : true;
                    }
                } else {
                    result = cacheHeader;
                }
                this.checkResult = result;
            } else {
                this.checkResult = Object.assign({}, this.checkHeader);
            }
            this.setTableHeader();
            const tableHeader = [];
            for (const header in this.checkHeader) {
                tableHeader.push(header);
            }
            this.tableHeader = tableHeader;
        },
        // 确认选择
        handleConfim() {
            this.isPopover = false;
            this.setTableHeader();
        },
        // 设置数据
        setTableHeader() {
            this.$emit('update:checkHeader', Object.assign({}, this.checkResult));

            this.cacheData[this.cacheKey] = this.checkResult;
            const cacheData = JSON.stringify(this.cacheData);
            localStorage.setItem(this.cacheLocalKey, cacheData);
        },
    },
};
</script>

<style lang="stylus" >
    .cacheTableHeaderSet
        margin-left: auto;
        .el-button
            width: .4rem;
            display: flex;
            justify-content: center;
            align-items: center
            &.setBtn
                border-radius: 2px
                background: #73C0DE
                color #fff !important
                text-align: center
                border none
                // .iconfont
                //     margin-right: 2px
    .tablePopover
        .popoverContent
            .el-checkbox
                width calc(100%/3)
                margin-right 0
                margin-bottom: 0.1rem
</style>
