<template>
    <div class="dispatch">
        <div class="searchBox">
            <div class="searchArea">
                <div>
                    <span style="font-size:.16rem">场站：</span>
                    <el-select v-model="stationId" clearable placeholder="请选择">
                        <template v-for="(item, index) in siteData">
                            <el-option
                                :key="`stationId${index}`"
                                :label="item.mixstation_name"
                                :value="item.mixstation_code"
                            >
                            </el-option>
                        </template>
                    </el-select>
                </div>
                <div>
                    <span style="font-size:.16rem">单位：</span>
                    <el-input v-model="company" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <span style="font-size:.16rem">工程：</span>
                    <el-input v-model="engineering" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <span style="font-size:.16rem">部位：</span>
                    <el-input v-model="position" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <span style="font-size:.16rem">标号：</span>
                    <el-input v-model="grade" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <span style="font-size:.16rem">浇筑方式：</span>
                    <el-input v-model="mode" placeholder="请输入内容"></el-input>
                </div>
            </div>
            <div class="searchArea">
                <div>
                    <span style="font-size:.16rem">销售方式：</span>
                    <el-select v-model="salesMethods" placeholder="请选择">
                        <template v-for="(item, index) in salesMethodsList">
                            <el-option
                                :key="`salesMethods${index}`"
                                :label="item.dic_name"
                                :value="item.dic_code"
                            >
                            </el-option>
                        </template>
                    </el-select>
                </div>
                <div>
                    <span style="font-size:.16rem">销售区域：</span>
                    <el-select v-model="salesArea" clearable placeholder="请选择">
                        <el-option
                            v-for="(item, index) in salesAreaDatas"
                            :key="index"
                            :label="item.name"
                            :value="item.name"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div>
                    <el-date-picker
                        v-model="timeSlot"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="pickerOptions"
                        :default-time="defaultTime"
                    >
                    </el-date-picker>
                </div>
                <div>
                    <el-button
                        @click="resetFun"
                        class="el-icon-refresh-right"
                        style="color: #000"
                    >
                        重置
                    </el-button>
                    <el-button type="primary" @click="conditionalQuery" class="el-icon-search">
                        查询
                    </el-button>
                </div>
            </div>
        </div>
        <div class="contntBox">
            <div class="head_btn">
                <div class="edit_btn">
                    <el-button
                        type="primary"
                        size="small"
                        class="el-icon-plus"
                        @click="newOrder"
                    >
                        新增订单
                    </el-button>
                    <el-button
                        type="primary"
                        size="small"
                        class="el-icon-download"
                        @click="batchSending"
                    >
                        批量下发
                    </el-button>
                    <!-- <el-button
                        type="primary"
                        size="small"
                        class="iconfont iconshangchuan2"
                        @click="exportFun"
                    >
                        导出
                    </el-button> -->
                </div>
                <tableHeaderSet
                    class="header_set_btn"
                    cache-key="dispathTable"
                    :check-header.sync="showHeader"
                >
                </tableHeaderSet>
            </div>
            <div class="table">
                <div class="table-bg">
                    <el-table
                        stripe
                        border
                        :data="tableData"
                        style="width: 100%"
                        v-loading="loading"
                        ref="ref_dispatch"
                        height="100%"
                        highlight-current-row
                        @selection-change="handleSelectionChange"
                        @row-click="getTemplateRow"
                        @header-dragend="colChange"
                    >
                        <!-- <el-table-column
                            prop="remarks"
                            label="备注"
                            show-overflow-tooltip
                            v-if="showHeader['备注']"
                            :width=" dispatchTableColWidths['备注'] || 200"
                        >
                        </el-table-column> -->
                        <el-table-column
                            label="颜色"
                            v-if="showHeader['颜色']"
                            :width=" dispatchTableColWidths['颜色'] || 50"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <div class="block_color_box">
                                    <div class="block_color" :style="{background: scope.row.block_color}">
                                        <i v-if="scope.row.block_color" class="iconfont iconyouqitong_huaban1"></i>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            type="selection"
                            width="50"
                        >
                        </el-table-column>
                        <el-table-column
                            label="任务状态"
                            v-if="showHeader['任务状态']"
                            :width=" dispatchTableColWidths['任务状态'] || 80"
                        >
                            <template slot-scope="scope">
                                <span v-if="scope.row.distributed_status == 0">未下发</span>
                                <span
                                    v-if="scope.row.distributed_status == 1"
                                    style="color:#33CC70"
                                    class="distributed_status"
                                >已下发</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="订单编号"
                            v-if="showHeader['订单编号']"
                            :width=" dispatchTableColWidths['订单编号'] || 180"
                        >
                            <template slot-scope="scope">
                                <div
                                    style="color: blue;cursor:pointer;text-align:left"
                                    @click="orderNumberClick(scope.row)"
                                >
                                    {{ scope.row.order_number }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="任务编号"
                            v-if="showHeader['任务编号']"
                            :width=" dispatchTableColWidths['任务编号'] || 180"
                        >
                            <template slot-scope="scope">
                                <div
                                    style="color: blue;cursor:pointer;text-align:left"
                                    @click="btnClickfunc(scope.row)"
                                >
                                    {{ scope.row.task_number }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="customer_name"
                            label="客户名称"
                            show-overflow-tooltip
                            v-if="showHeader['客户名称']"
                            :width=" dispatchTableColWidths['客户名称'] || 220"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="sgdw_name"
                            label="施工单位"
                            show-overflow-tooltip
                            v-if="showHeader['施工单位']"
                            :width=" dispatchTableColWidths['施工单位'] || 220"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="project_name"
                            label="工程名称"
                            show-overflow-tooltip
                            v-if="showHeader['工程名称']"
                            :width=" dispatchTableColWidths['工程名称'] || 240"
                        >
                        </el-table-column>
                        <el-table-column
                            label="产品标号"
                            show-overflow-tooltip
                            v-if="showHeader['产品标号']"
                            :width=" dispatchTableColWidths['产品标号'] || 100"
                        >
                            <template slot-scope="scope">
                                <span>
                                    {{ scope.row.strength_grade_name+' '+scope.row.special_require_name }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="place_detail"
                            label="施工部位"
                            show-overflow-tooltip
                            v-if="showHeader['施工部位']"
                            :width=" dispatchTableColWidths['施工部位'] || 160"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="pouring_name"
                            label="浇筑方式"
                            v-if="showHeader['浇筑方式']"
                            :width=" dispatchTableColWidths['浇筑方式'] || 80"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="pump_truck_name"
                            label="泵车类型"
                            v-if="showHeader['泵车类型']"
                            :width=" dispatchTableColWidths['泵车类型'] || 80"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="slump"
                            label="坍落度"
                            v-if="showHeader['坍落度']"
                            :width=" dispatchTableColWidths['坍落度'] || 80"
                        >
                            <template slot-scope="scope">
                                <span>{{ scope.row.slump }}</span><i>±</i><span>{{ scope.row.slump_name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="plan_quantity"
                            label="计划方量"
                            v-if="showHeader['计划方量']"
                            :width=" dispatchTableColWidths['计划方量'] || 80"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="quantity"
                            label="任务方量"
                            v-if="showHeader['任务方量']"
                            :width=" dispatchTableColWidths['任务方量'] || 80"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="grand_total_quantity"
                            label="完成方量"
                            v-if="showHeader['完成方量']"
                            :width=" dispatchTableColWidths['完成方量'] || 80"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="grand_total_vehicle"
                            label="累计车数"
                            v-if="showHeader['累计车数']"
                            :width=" dispatchTableColWidths['累计车数'] || 80"
                        >
                        </el-table-column>
                        <el-table-column
                            label="生产站点"
                            prop="station_name"
                            v-if="showHeader['生产站点']"
                            :width=" dispatchTableColWidths['生产站点'] || 100"
                        >
                        </el-table-column>
                        <el-table-column
                            label="下发时间"
                            v-if="showHeader['下发时间']"
                            :width=" dispatchTableColWidths['下发时间'] || 200"
                        >
                            <template slot-scope="scope">
                                <span>{{ scope.row.distributed_time }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="用料时间"
                            v-if="showHeader['用料时间']"
                            :width=" dispatchTableColWidths['用料时间'] || 140"
                        >
                            <template slot-scope="scope">
                                <span>{{ scope.row.arrival_time.substr(0,10) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="creator_name"
                            label="申请人"
                            show-overflow-tooltip
                            v-if="showHeader['申请人']"
                            :width=" dispatchTableColWidths['申请人'] || 100"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="confirmed"
                            label="审核通过时间"
                            show-overflow-tooltip
                            v-if="showHeader['审核通过时间']"
                            :width=" dispatchTableColWidths['审核通过时间'] || 160"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="操作"
                            fixed="right"
                            v-if="showHeader['操作']"
                            :width=" dispatchTableColWidths['操作'] || 200"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    v-if="scope.row.src_task_number != null && scope.row.src_task_number != ''"
                                    @click="demolitionOrder(scope.row, 1)"
                                    type="text"
                                    size="small"
                                >
                                    维护
                                </el-button>
                                <el-button
                                    v-else
                                    @click="demolitionOrder(scope.row, 2)"
                                    type="text"
                                    size="small"
                                >
                                    拆单
                                </el-button>
                                <el-button
                                    @click="taskAssignment(scope.row)"
                                    type="text"
                                    size="small"
                                >
                                    下发
                                </el-button>
                                <el-button
                                    @click="taskOrder(scope.row)"
                                    v-if="scope.row.contract_type !== '102-LS2'"
                                    type="text"
                                    size="small"
                                >
                                    复制
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="table-page">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[10, 20, 30, 40, 50, 100]"
                        :page-size="10"
                        layout="total, sizes, prev, pager, next"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
            <template v-if="siteData.length>0">
                <div class="left_btn" v-if="!blockColorFlag" @click="blockColorFlag = true"></div>
            </template>
            <div class="schedule" v-show="blockColorFlag">
                <div class="tabs">
                    <div class="tab_box">
                        <template v-for="(item, index) in siteData">
                            <div
                                :name="item.mixstation_code"
                                :key="index"
                                class="tab_item"
                                :class="{active_tab: activeName === item.mixstation_code}"
                                @click="handleClick({ ...item, index,})"
                            >
                                <span :class="{'defaultStation': item.mixstation_code == defaultStation}">
                                    {{ item.mixstation_name }}
                                    <strong>（{{ item.capacity }}m³/h）</strong>
                                    <i></i>
                                </span>
                            </div>
                        </template>
                    </div>
                    <div class="tab_right">
                        <div class="tip" :class="{tip_no: isMask}">
                            <i class="iconfont icon2"></i>
                            提示：按住shift多选
                        </div>
                        <div v-if="isMask" class="take_no">
                            当前站点无法排产！
                        </div>
                        <div v-else class="take">
                            每格产能 <i>{{ capacity }}</i>方, 当前已排产<i>{{ selectSquareQuantity }}</i>方。
                        </div>
                        <!-- <div class="take_text">
                            每格产能 <i>{{ capacity }}</i>方, 当前已排产<i>{{ selectSquareQuantity }}</i>方。
                        </div> -->
                        <div class="get_back" @click="blockColorFlag = false">
                            <div class="back_box">
                                <span style="margin-top: 5px;">返回</span>
                                <span style="margin-top: -4px;" class="el-icon-caret-bottom"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="colorBlock">
                    <div class="mask" v-if="isMask"></div>
                    <div class="condition">
                        <div class="selectColor el_left">
                            <p>
                                选择颜色
                            </p>
                            <!-- <p class="iconfont iconyanse">
                                选择颜色：
                            </p> -->
                            <template v-for="(item, index) in selectColorData">
                                <!-- eslint-disable-next-line vue/require-v-for-key -->
                                <div class="clean-color" v-if="index === selectColorData.length - 1" :key="index">
                                    <div
                                        class="select-color"
                                        v-if="colorIndex !== selectColorData.length - 1"
                                        style="cursor: pointer;"
                                        @click="selectColor(index, item.backgroundColor)"
                                    >
                                        <!-- <img
                                            class="activeColor"
                                            src="../../assets/images/productionManagement/eraser.png"
                                            :class="{'active': colorIndex == index}"
                                        > -->
                                        <i class="iconfont iconqingchu"></i>
                                        <p>清除</p>
                                    </div>
                                    <div
                                        v-if="colorIndex === selectColorData.length - 1"
                                        class="color_box"
                                        :class="{'active': colorIndex == index}"
                                        @click="selectColor(index, item.backgroundColor)"
                                    >
                                        <span
                                            :key="index"
                                            :style="item"
                                            style="width:.25rem;height:.25rem;"
                                            :class="{'active': colorIndex == index}"
                                        ></span>
                                        <span v-if="item.text">
                                            {{ item.text }}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    v-else
                                    :key="index"
                                    class="color_box"
                                    :class="{'active': colorIndex == index}"
                                    @click="selectColor(index, item.backgroundColor)"
                                >
                                    <span
                                        :style="item"
                                        style="width:.25rem;height:.25rem;"
                                        :class="{'active': colorIndex == index}"
                                    >
                                        <i style="color: #ffffff; font-size: .18rem" class="iconfont iconyouqitong_huaban1"></i>
                                    </span>
                                    <span>
                                        {{ item.text }}
                                    </span>
                                </div>
                            </template>
                            <button class="Determine" @click="confirmSelection">
                                确定
                            </button>
                            <button class="Reset" @click="resetSelectionData">
                                重置
                            </button>
                        </div>
                        <div class="selectTime el_right">
                            <!-- <p class="iconfont iconshijian1">
                                时间：
                            </p> -->
                            <span>
                                {{ currentTime }}
                                <strong>00:00~24:00</strong>
                            </span>
                            <button @click="lastDay">
                                上一天
                            </button>
                            <button @click="sameDay">
                                当天
                            </button>
                            <button @click="lastTime">
                                下一天
                            </button>
                        </div>
                    </div>
                    <div
                        class="planTable"
                        @mousedown="tableMousedown($event)"
                        @mousemove.stop="tableMousemove($event)"
                        @mouseup.stop="tableMouseup($event)"
                        @mouseleave="tableMouseleave($event)"
                    >
                        <div
                            id="moveSelected"
                            :style="{top:moveSelectedTop, left: moveSelectedLeft, width: moveSelectedWidth, height:moveSelectedHeight,}"
                        ></div>
                        <table>
                            <tbody>
                                <tr>
                                    <template v-for="(val,index) in planData">
                                        <td :key="index">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {{ val.label }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-for="(k, i) in val.data">
                                                        <tr :key="i">
                                                            <td>
                                                                <span
                                                                    :style="{backgroundColor: k.block_color}"
                                                                    @click="signPlan(k)"
                                                                    @dblclick="removeColorBlock(k)"
                                                                    :timeIndex="index"
                                                                    :index="i"
                                                                ></span>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- 拆单/维护弹窗 -->
        <div class="maintain-or-order" v-if="messageBox">
            <div class="order-info">
                <div class="header-info">
                    <span class="iconfont"></span>
                    <h1>{{ titleName }}</h1>
                    <span @click="closeAlert" class="iconfont close-alert">&#xe65f;</span>
                </div>
                <div class="detailed-info">
                    <div class="setBorder">
                        <table>
                            <tbody>
                                <tr>
                                    <th>订单编号：</th>
                                    <td>
                                        <el-input type="text" :value="messageInfo.order_number" readonly="readonly"></el-input>
                                    </td>
                                    <th>任务单号：</th>
                                    <td>
                                        <el-input type="text" :value="messageInfo.task_number" readonly="readonly"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th>客户名称：</th>
                                    <td colspan="3">
                                        <el-input :value="messageInfo.customer_name" type="text" readonly="readonly"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th>工程名称：</th>
                                    <td colspan="3">
                                        <el-input type="text" :value="messageInfo.project_name" readonly="readonly"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th>工程部位：</th>
                                    <td colspan="3">
                                        <el-input type="text" :value="messageInfo.place_detail" readonly="readonly"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th>订单方量：</th>
                                    <td>
                                        <el-input type="text" :value="messageInfo.plan_quantity" readonly="readonly"></el-input>
                                    </td>
                                    <th>要求到货时间：</th>
                                    <td>
                                        <el-input :value="messageInfo.arrival_time" type="text" readonly="readonly"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th>供应场站：</th>
                                    <td>
                                        <el-input type="text" :value="messageInfo.station_name" readonly="readonly"></el-input>
                                    </td>
                                    <th>任务方量：</th>
                                    <td>
                                        <el-input type="text" v-model="taskFangLiang" readonly="readonly"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th><span class="zhongdian">* </span>调配场站：</th>
                                    <td>
                                        <el-select
                                            v-if="clickStatus !== 1"
                                            value-key="mixstation_name"
                                            v-model="value2"
                                            placeholder="请选择"
                                            style="width:100%"
                                        >
                                            <el-option
                                                v-for="item in inputSiteDara"
                                                :key="item.mixstation_code"
                                                :label="item.mixstation_name"
                                                :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                        <el-input v-else :value="value2.mixstation_name" readonly="readonly"></el-input>
                                    </td>
                                    <th><span class="zhongdian">* </span>任务方量：</th>
                                    <td>
                                        <el-input
                                            @mousewheel.native.prevent
                                            @input="taskNumberFun"
                                            v-model.number="rwfl"
                                            placeholder="请输入任务方量"
                                            type="number"
                                        ></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th><span class="zhongdian">* </span>运距：</th>
                                    <td colspan="3">
                                        <el-input type="number" v-model="haul_distance" placeholder="请输入运距"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th>发货单抬头：</th>
                                    <td colspan="3" @click="chooseBillRemark">
                                        <el-input
                                            placeholder="请选择发货单抬头"
                                            v-model="task_title"
                                            readonly
                                            style="cursor: pointer"
                                        ></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <th>备注：</th>
                                    <td colspan="3">
                                        <el-input v-model="messageInfo.remarks" placeholder="请输入备注"></el-input>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="bottom-btn">
                    <el-button @click="closeAlert">
                        取 消
                    </el-button>
                    <el-button type="primary" @click="taskSplit">
                        保存
                    </el-button>
                </div>
            </div>
        </div>
        <!-- 批量发送弹窗 -->
        <div class="batch-sending-alert" v-show="batchSendingAlert">
            <div class="alert-content">
                <div class="header-info">
                    <span class="iconfont"></span>
                    <h1>批量下发</h1>
                    <span @click="hideDownAlert" class="iconfont close-alert">&#xe65f;</span>
                </div>
                <div class="checkbox-list">
                    <el-table
                        :data="checkBoxListTwo"
                        height="350"
                        style="width: 100%"
                    >
                        <el-table-column
                            label="序号"
                            align="center"
                            type="index"
                            width="50"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="task_number"
                            label="任务单号"
                            width="180"
                            align="center"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="project_name"
                            label="工程名称"
                            align="center"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="strength_grade_name"
                            label="强度等级"
                            align="center"
                        >
                        </el-table-column>
                        <el-table-column
                            label="状态"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <span v-if="scope.row.distributed_status == 0">未下发</span>
                                <span v-else-if="scope.row.distributed_status == 1">已下发</span>
                                <span v-else>{{ scope.row.distributed_status }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="batch-sending-btn">
                    <el-button
                        type="plain"
                        @click="hideDownAlert"
                    >
                        取消
                    </el-button>
                    <el-button
                        type="primary"
                        @click="batchdistributed"
                    >
                        确认下发
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'loadsh';
import { eventBus } from '@/common/eventBus.ts';
import tableHeaderSet from './component/cacheTableHeaderSet';
import utils from '@/utils/util';
export default {
    // 生产管理-生产计划调度下发
    name: 'dispatch',
    components: { tableHeaderSet },
    props: {},
    data() {
        const self = this;
        return {
            // 列表缓存宽度
            dispatchTableColWidths: '',
            haul_distance: 0, // 拆单界面，运距默认改为0[禅道任务784]
            // 发货单抬头
            task_title: '',
            // 是否协同 0 否 1 是
            is_teamwork: 1,
            value3: '',
            value2: '',
            beizhu: '',
            beizhu2: '',
            rwfl: '',
            taskFangLiang: 0,
            mixstationData: [],
            // 批量发送弹窗
            batchSendingAlert: false,
            checkBoxList: [],
            checkBoxListTwo: [],
            checkBoxListThree: [],
            // 只读场站
            onlyReadyStation: '',
            // 点击的是拆单还是维护
            clickStatus: 0,
            // 弹窗信息相关
            messageInfo: {},
            messageBox: false,
            titleName: '',
            // 搜索-场站
            stationId: '',
            // 搜索-单位
            company: '',
            // 搜索-工程
            engineering: '',
            // 搜索-部位
            position: '',
            // 搜索-标号
            grade: '',
            // 搜索-浇筑方式
            mode: '',
            // 搜索-销售方式
            salesMethods: '',
            // 销售方式下拉数据
            salesMethodsList: [{
                dic_name: '全部',
                dic_code: '',
            }],
            // 搜索-销售区域
            salesArea: '',
            // 销售区域下拉数据
            salesAreaDatas: [],
            // 搜索-时间段
            timeSlot: [],
            defaultTime: ['06:00:00', '06:00:00'],
            // 任务列表数据
            tableData: [],
            // 任务列表状态
            loading: false,
            // 任务列表总条数
            total: 0,
            // 任务列表单选
            radio: '',
            // 任务列表选择数据
            selectionTaskData: [],
            // 站点数据集合
            siteData: [],
            // 当前选中站点
            activeName: '',
            // 当前任务默认站点
            defaultStation: '',
            // 当前站点编码
            defaultStiteCode: '',
            // 当前站点是否是默认站点
            isMask: false,
            radioColor: {},
            // 当前站点产能
            capacity: '',
            blockColorFlag: false,
            // 选择颜色数据集合
            selectColorData: [
                {
                    backgroundColor: '#55C072',
                    borderColor: '#55C072',
                    text: '绿色',
                },
                {
                    backgroundColor: '#F09529',
                    borderColor: '#F09529',
                    text: '橘色',
                },
                {
                    backgroundColor: '#A9C24B',
                    borderColor: '#A9C24B',
                    text: '青色',
                },
                {
                    backgroundColor: '#29C6EF',
                    borderColor: '#29C6EF',
                    text: '天蓝',
                },
                {
                    backgroundColor: '#FF5C5C',
                    borderColor: '#FF5C5C',
                    text: '红色',
                },
                {
                    backgroundColor: '#2990F0',
                    borderColor: '#2990F0',
                    text: '深蓝',
                },
                {
                    backgroundColor: '#979EA7',
                    borderColor: '#D7D7D7',
                    text: '',
                },
            ],
            // 计划调度表格数据集合
            planData: [],
            // 当前颜色下标
            colorIndex: 0,
            // 当前选择颜色
            currentColor: '#55C072',
            // 当前日期
            currentTime: this.getDateStr(0),
            // 选择日期变量
            day: 0,
            // 列表当前页数
            currentPage: 0,
            // 鼠标拖拽出的遮罩元素位置-top
            moveSelectedTop: '',
            // 鼠标拖拽出的遮罩元素位置-left
            moveSelectedLeft: '',
            // 鼠标拖拽出的遮罩元素位置-right
            moveSelectedRight: '',
            // 鼠标拖拽出的遮罩元素位置-bottom
            moveSelectedBottom: '',
            // 鼠标拖拽出的遮罩元素宽
            moveSelectedWidth: '',
            // 鼠标拖拽出的遮罩元素高
            moveSelectedHeight: '',
            // 是否开启拖拽的标志
            flag: false,
            // 鼠标按下时的left
            oldLeft: 0,
            // 鼠标按下时的top
            oldTop: 0,
            // 拖拽，选中的块数据集合
            selectedList: [],
            // 当前选择方量
            selectSquareQuantity: 0,
            // 要删除的块集合
            delectList: [],
            // 当前任务已保存块，重置用
            currentTaskPreservation: [],
            rowsData: {},
            taskNumber: '',
            selectIndex: '',
            everyPage: 10, // 每页多少条
            nowPage: 1, // 当前第N页
            inputStatus: false,
            inputSiteDara: [],
            tabIndex: 0,
            splitQuantity: 0,
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        end.setTime(start.getTime() + 3600 * 1000 * 24);
                        picker.$emit('pick', self.getDateRange([start, end]));
                    },
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', self.getDateRange([start, end]));
                    },
                }, {
                    text: '明天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() + 3600 * 1000 * 24);
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 2);
                        picker.$emit('pick', self.getDateRange([start, end]));
                    },
                }],
            },

            // 缓存显示表头
            showHeader: {
                备注: true,
                颜色: true,
                任务状态: true,
                订单编号: true,
                任务编号: true,
                客户名称: true,
                施工单位: true,
                工程名称: true,
                产品标号: true,
                施工部位: true,
                浇筑方式: true,
                泵车类型: true,
                坍落度: true,
                计划方量: true,
                任务方量: true,
                完成方量: true,
                累计车数: true,
                生产站点: true,
                下发时间: true,
                用料时间: true,
                申请人: true,
                审核通过时间: true,
                操作: true,
            },
        };
    },
    watch: {
        tableData() {
            this.$nextTick(() => {
                if (!this.radioColor.station || this.radioColor === {}) {
                    this.$refs.ref_dispatch.setCurrentRow(this.tableData[0]);
                    this.$refs.ref_dispatch.toggleRowSelection(this.tableData[0]);
                }
            });
        },
    },
    filters: {
        formatMinutes(value) {
            const date = new Date(value);
            const hour = date.getHours();
            const minutes = date.getMinutes();
            return (hour > 10 ? hour : '0' + hour) + ':'
            + (minutes > 10 ? minutes : '0' + minutes);
        },
    },
    computed: {},
    methods: {
        // 重置
        resetFun() {
            // 搜索-场站
            this.stationId = '';
            // 搜索-单位
            this.company = '';
            // 搜索-工程
            this.engineering = '';
            // 搜索-部位
            this.position = '';
            // 搜索-标号
            this.grade = '';
            // 搜索-浇筑方式
            this.mode = '';
            // 搜索-销售方式
            this.salesMethods = '';
            // 搜索-销售区域
            this.salesArea = '';

            // 搜索-时间段
            // 时间范围赋值
            const start = this.getDateStr(-3) + ' ' + this.defaultTime[0];
            const end = this.getDateStr(+2) + ' ' + this.defaultTime[1];
            this.timeSlot = [start, end];
            // 获取列表数据
            this.gitTaskListData();
        },
        taskOrder(row) {
            if (row) {
                this.$axios
                    .post('/interfaceApi/sale/contract_manager/is_contract_close_by_number/' + row.contract_number)
                    .then(res => {
                        if (!res) {
                            this.$YWShowLayer(`FormCode=PO202001140931270228&subject=1&order_number=${row.order_number}`, '', '');
                        } else {
                            this.$message({
                                message: '合同已关闭，不能复制新增!',
                                type: 'warning',
                            });
                        }
                    });
            } else {
                this.$YWShowLayer('FormCode=PO202001140931270228&subject=1', '', '');
            }
        },
        // 取消按钮
        closeAlert() {
            // 发货单抬头
            this.task_title = '';
            // 是否协同 0 否 1 是
            this.is_teamwork = 1;
            this.value3 = '';
            this.value2 = '';
            this.rwfl = '';
            this.beizhu = '';
            this.beizhu2 = '';
            this.mixstationData = [];
            this.messageBox = false;
        },
        // 拆单
        demolitionOrder(row, status) {
            this.clickStatus = status;
            if (status === 1) {
                this.titleName = '维护';
            } else {
                this.titleName = '拆单';
                this.value3 = '';
            }
            this.rowsData = row;
            // this.messageBox = true;
            this.loadsplitdata(row.task_number, status);
        },
        // 任务列表条件查询
        conditionalQuery() {
            // 获取列表数据
            this.gitTaskListData();
        },
        // 关闭下发弹窗
        hideDownAlert() {
            this.batchSendingAlert = false;
            this.checkBoxListTwo = [];
            this.gitTaskListData();
            this.gitplanData();
        },
        exportFun() {
            console.log('导出');
        },
        // 唤醒批量发送弹窗
        batchSending() {
            this.checkBoxList.forEach(item => {
                let obj = {};
                obj = {
                    task_number: item.task_number,
                    strength_grade_name: item.strength_grade_name,
                    project_name: item.project_name,
                    distributed_status: item.distributed_status,
                };
                this.checkBoxListTwo.push(obj);
            });
            if (this.checkBoxListTwo.length > 0 && this.checkBoxListTwo) {
                // 符合条件唤醒弹窗
                this.batchSendingAlert = true;
            } else {
                this.$message.error('请选择要下发的生产计划');
            }
        },
        // checkBox多选
        handleSelectionChange(value) {
            this.checkBoxList = value;
        },
        // 任务列表当前选择数据
        selectionTask(index, data) {
            this.selectionTaskData = data;
        },
        // 批量下发接口
        batchdistributed() {
            // 点击确认下发
            this.checkBoxListThree = [];
            const arr = [];
            this.checkBoxListTwo.forEach(item => {
                arr.push(item.task_number);
            });
            // 接口只需要其中的task_number取出来调用接口
            this.$axios
                .post('/interfaceApi/production/producttask/batchdistributed', arr)
                .then(res => {
                    // const arr2 = [];
                    res.forEach(item => {
                        this.checkBoxListTwo.forEach((items, index) => {
                            if (item.task_number === items.task_number) {
                                this.$set(this.checkBoxListTwo[index], 'distributed_status', item.message);
                            }
                        });
                    });
                    this.$message.success('下发成功');
                })
                .catch(() => {
                    this.$message.error('下发失败');
                });
        },
        // 切换场站
        handleClick(tab) {
            this.activeName = tab.mixstation_code;
            this.tabIndex = tab.index;
            this.selectColorData.forEach((item, index) => {
                if (this.tableData.block_color === index) {
                    this.colorIndex = index;
                }
            });
            this.selectedList = [];
            // 当前厂站编码
            this.defaultStiteCode = this.siteData[tab.index].mixstation_code;
            // 判断当前切换站点是不是默认站点
            if (this.defaultStation !== this.defaultStiteCode) {
                this.isMask = true;
            } else {
                this.isMask = false;
            }
            // 当前厂站产能
            this.capacity = this.siteData[tab.index].capacity / 20;
            // 获取默认场站已选择块
            this.gitSelectionData();
        },
        // 选择颜色
        selectColor(index, color) {
            this.selectIndex = index;
            if (this.selectedList.length > 0 && this.selectedList[0].block_color !== color && index !== this.selectColorData.length - 1) {
                this.$message({
                    message: '当前任务已选择块，不能切换颜色。',
                    type: 'warning',
                });
            } else if (this.radio.block_color !== '' && this.radio.block_color !== color && index !== this.selectColorData.length - 1 ) {
                this.$message({
                    message: '当前任务已有选择颜色，不能切换颜色。',
                    type: 'warning',
                });
            } else {
                this.colorIndex = index;
                this.currentColor = color;
            }
        },
        // 删除色块
        removeColorBlock(data) {
            const isSelectedList = this.selectedList.find(v => {
                return v.block_sq === data.block_sq;
            });
            if (isSelectedList !== 'undefined') {
                data.block_color = '';
            }
            if (typeof isSelectedList === 'undefined' && data.task_number && data.order_number) {
                data.block_color = '';
                // 删除已保存的块
                this.deleteSelectionData(data, 'delete');
                // 数据更新强制刷新页面
                this.$forceUpdate();
            }
        },
        // 标记计划
        signPlan(data) {
            if (this.colorIndex === this.selectColorData.length - 1) {
                const isSelectedList = this.selectedList.find(v => {
                    return v.block_sq === data.block_sq;
                });
                if (isSelectedList !== 'undefined') {
                    data.block_color = '';
                }
                if (typeof isSelectedList === 'undefined' && data.task_number && data.order_number) {
                    data.block_color = '';
                    // 删除已保存的块
                    this.deleteSelectionData(data);
                    // 数据更新强制刷新页面
                    this.$forceUpdate();
                } else {
                    this.selectedList.forEach((item, index) => {
                        if (item.block_sq === data.block_sq) {
                            this.selectedList.splice(index, 1);
                        }
                    });
                    // 当前任务选择方量
                    this.selectSquareQuantity = this.formatNumber(
                        (this.selectedList.length + this.currentTaskPreservation.length) * this.capacity
                    );
                }

            } else {
                const isMarked = this.currentTaskPreservation.find(v => {
                    return v.block_sq === data.block_sq;
                });
                if (typeof isMarked === 'undefined') {
                    // 给数据里对应位置赋值block_color
                    data.block_color = this.currentColor;
                    // 给数据里对应位置赋值task_number
                    data.task_number = this.radio.task_number;
                    // 给数据里对应位置赋值order_number
                    data.order_number = this.radio.order_number;
                    // 给数据里对应位置赋值plan_date
                    data.plan_date = this.radio.plan_date;
                    // 给数据里对应位置赋值station
                    data.station = this.defaultStiteCode;
                    // 给数据里对应位置赋值block_capacity
                    data.block_capacity = this.capacity;
                    // 拖拽，选中的块数据集合
                    this.selectedList.push(data);
                    // 多选去重
                    this.selectedList = Array.from(new Set(this.selectedList));
                    // 当前任务选择方量
                    this.selectSquareQuantity = this.formatNumber(
                        (this.selectedList.length + this.currentTaskPreservation.length) * this.capacity
                    );
                }
            }
        },

        // 上一天
        lastDay() {
            this.day--;
            this.currentTime = this.getDateStr(this.day);
            // 获取默认场站已选择块
            this.gitSelectionData();
        },

        // 下一天
        lastTime() {
            this.day++;
            this.currentTime = this.getDateStr(this.day);
            // 获取默认场站已选择块
            this.gitSelectionData();
        },

        // 当天
        sameDay() {
            this.day = 0;
            this.currentTime = this.getDateStr(0);
            // 获取默认场站已选择块
            this.gitSelectionData();
        },

        // 时间处理
        getDateStr(dayCount) {
            if (null === dayCount) {
                dayCount = 0;
            }
            const dd = new Date();
            dd.setDate(dd.getDate() + dayCount);
            const y = dd.getFullYear();
            let m = dd.getMonth() + 1;
            let d = dd.getDate();
            if (m < 10) {
                m = '0' + m;
            }
            if (d < 10) {
                d = '0' + d;
            }
            return y + '-' + m + '-' + d;
        },
        // 每页多少条数据
        handleSizeChange(val) {
            this.everyPage = val;
            this.gitTaskListData();
        },
        // 当前第几页
        handleCurrentChange(val) {
            this.nowPage = val;
            this.gitTaskListData();
        },
        // 时间转化
        timeFormat(date, status) {
            const date2 = new Date(date);
            const y = date2.getFullYear(); // 年
            let m = date2.getMonth() + 1; // 月
            let d = date2.getDate(); // 日
            let t = date2.getHours(); // 时
            let f = date2.getMinutes(); // 分
            // const s = date.getSeconds(); // 秒
            m = m > 9 ? m : '0' + m;
            d = d > 9 ? d : '0' + d;
            t = t > 9 ? t : '0' + t;
            f = f > 9 ? f : '0' + f;
            if (status === 'noFS') {
                return `${y}-${m}-${d}`;
            }
            if (status === 'zyTime') {
                return `${y}-${m}-${d} ${t}:${f}`;
            }
            return `${y}-${m}-${d} 00:00`;
        },
        // 获取计划调度表格鼠标按下时开启拖拽多选，将遮罩定位并展现
        tableMousedown(event) {
            // 判断键盘shift是否被按下
            if (event.shiftKey) {
                this.flag = true;
                // 鼠标拖拽出的遮罩元素位置-top
                this.moveSelectedTop = event.pageY + 'px';
                // 鼠标拖拽出的遮罩元素位置-left
                this.moveSelectedLeft = event.pageX + 'px';
                // 鼠标按下时的left
                this.oldLeft = event.pageX;
                // 鼠标按下时的left
                this.oldTop = event.pageY;
            }
        },

        // 获取计划调度表格鼠标移动时计算遮罩的位置，宽 高
        tableMousemove(event) {
            if (!this.flag) {return;} // 只有开启了拖拽，才进行mouseover操作
            if (event.pageX < this.oldLeft) { // 向左拖
                this.moveSelectedLeft = event.pageX + 'px';
                this.moveSelectedWidth = this.oldLeft - event.pageX + 'px';
            } else {
                this.moveSelectedWidth = event.pageX - this.oldLeft + 'px';
            }
            if (event.pageY < this.oldTop) { // 向上
                this.moveSelectedTop = event.pageY + 'px';
                this.moveSelectedHeight = this.oldTop - event.pageY + 'px';
            } else {
                this.moveSelectedHeight = event.pageY - this.oldTop + 'px';
            }
        },

        // 获取计划调度表格鼠标抬起时计算遮罩的right 和 bottom，找出遮罩覆盖的块，关闭拖拽选中开关，清除遮罩数据
        tableMouseup() {
            if (!this.flag) {return;} // 只有开启了拖拽，才进行操作
            this.moveSelectedBottom = Number(this.moveSelectedTop.split('px')[0]) + Number(this.moveSelectedHeight.split('px')[0]);
            this.moveSelectedRight = Number(this.moveSelectedLeft.split('px')[0]) + Number(this.moveSelectedWidth.split('px')[0]);
            this.findSelected();
            this.flag = false;
            this.tableMouseleave();
        },

        // 获取计划调度表格,计算选中区域
        findSelected() {
            let isDelete = false;
            const _that = this;
            const blockList = $('.planTable').find('span');
            for (let i = 0; i < blockList.length; i++) {
                // 计算每个块的定位信息
                const left = $(blockList[i]).offset().left;
                const right = $(blockList[i]).width() + left;
                const top = $(blockList[i]).offset().top;
                const bottom = $(blockList[i]).height() + top;
                // 判断每个块是否被遮罩盖住（即选中）
                const leftFlag = _that.moveSelectedLeft.split('px')[0] <= left && left <= _that.moveSelectedRight;
                const rightFlag = _that.moveSelectedLeft.split('px')[0] <= right && right <= _that.moveSelectedRight;
                const topFlag = _that.moveSelectedTop.split('px')[0] <= top && top <= _that.moveSelectedBottom;
                const bottomFlag = _that.moveSelectedTop.split('px')[0] <= bottom && bottom <= _that.moveSelectedBottom;
                if ((leftFlag || rightFlag) && (topFlag || bottomFlag)) {
                    // 获取表格横向下标
                    const timeIndex = $(blockList[i]).attr('timeIndex');
                    // 获取表格纵向下标
                    const Index = $(blockList[i]).attr('index');

                    // 判断已保存块中是否已存在当前选择块
                    const isMarked = _that.currentTaskPreservation.find(v => {
                        return v.block_sq === _that.planData[timeIndex].data[Index].block_sq;
                    });
                    if (_that.colorIndex === this.selectColorData.length - 1) {
                        _that.planData[timeIndex].data[Index].block_color = '';
                        if (typeof isMarked !== 'undefined') {
                            isDelete = true;
                            _that.delectList.push(_that.planData[timeIndex].data[Index]);
                        } else {
                            _that.selectedList.forEach((item, index) => {
                                if (item.block_sq === _that.planData[timeIndex].data[Index].block_sq) {
                                    _that.selectedList.splice(index, 1);
                                }
                            });
                            // 当前任务选择方量
                            _that.selectSquareQuantity = _that.formatNumber(
                                // this.currentTaskPreservation.length * this.capacity
                                (_that.selectedList.length + _that.currentTaskPreservation.length) * _that.capacity
                            );
                        }
                    } else if (typeof isMarked === 'undefined') {
                        // 给数据里对应位置赋值block_color
                        _that.planData[timeIndex].data[Index].block_color = _that.currentColor;
                        // 给数据里对应位置赋值task_number
                        _that.planData[timeIndex].data[Index].task_number = _that.radio.task_number;
                        // 给数据里对应位置赋值order_number
                        _that.planData[timeIndex].data[Index].order_number = _that.radio.order_number;
                        // 给数据里对应位置赋值plan_date
                        _that.planData[timeIndex].data[Index].plan_date = _that.radio.plan_date;
                        // 给数据里对应位置赋值station
                        _that.planData[timeIndex].data[Index].station = _that.defaultStiteCode;
                        // 给数据里对应位置赋值block_capacity
                        _that.planData[timeIndex].data[Index].block_capacity = _that.capacity;
                        // 拖拽，选中的块数据集合
                        _that.selectedList.push(_that.planData[timeIndex].data[Index]);
                    }
                }
            }
            if (_that.colorIndex === this.selectColorData.length - 1 && isDelete) {
                // 删除已保存的块
                _that.confirmSelection('delete');
            }
            // 多选去重
            _that.selectedList = Array.from(new Set(_that.selectedList));
            // 当前任务选择方量
            // _that.selectSquareQuantity = _that.formatNumber(_that.currentTaskPreservation.length * _that.capacity);

            _that.selectSquareQuantity = _that.formatNumber((_that.selectedList.length + _that.currentTaskPreservation.length) * _that.capacity);
        },

        // 鼠标移出计划调度表格，遮罩层数据复原
        tableMouseleave() {
            // 鼠标拖拽出的遮罩元素位置-top
            this.moveSelectedTop = '';
            // 鼠标拖拽出的遮罩元素位置-left
            this.moveSelectedLeft = '';
            // 鼠标拖拽出的遮罩元素位置-right
            this.moveSelectedRight = '';
            // 鼠标拖拽出的遮罩元素位置-bottom
            this.moveSelectedBottom = '';
            // 鼠标拖拽出的遮罩元素宽
            this.moveSelectedWidth = '';
            // 鼠标拖拽出的遮罩元素高
            this.moveSelectedHeight = '';
            // 是否开启拖拽的标志
            this.flag = false;
        },
        taskNumberFun() {
            // 订单方量
            const a = this.messageInfo.plan_quantity;
            // 拆出方量
            // const b = this.messageInfo.split_quantity;
            if (this.inputStatus) {
                this.taskFangLiang = a - this.rwfl;
            } else {
                this.taskFangLiang = a - this.splitQuantity - this.rwfl;
            }
        },
        // 生产任务拆单
        taskSplit() {
            if (!this.value2.mixstation_code) {
                this.$message.error('请选择调配场站');
            } else if (this.rwfl <= 0) {
                this.$message.error('请输入任务方量');
                return;
            } else if (this.taskFangLiang < 0) {
                this.$message.error('已超出输入方量');
            } else if (this.haul_distance === null || this.haul_distance === '') {
                this.$message.error('请输入运距');
                return;
            } else if (this.messageInfo.src_task_number) {
                this.$axios
                    .post('/interfaceApi/production/producttask/splitadjust', {
                        task_number: this.taskNumber,
                        task_title: this.task_title,
                        quantity_extend: this.rwfl,
                        station_extend: this.value2.mixstation_code,
                        station_name_extend: this.value2.mixstation_name,
                        is_teamwork: this.is_teamwork,
                        haul_distance: this.haul_distance,
                        remarks: this.messageInfo.remarks,
                    })
                    .then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success',
                        });
                        this.messageBox = false;
                        this.gitTaskListData();
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$axios
                    .post('/interfaceApi/production/producttask/split', {
                        task_number: this.taskNumber,
                        quantity_extend: this.rwfl,
                        station_extend: this.value2.mixstation_code,
                        station_name_extend: this.value2.mixstation_name,
                        task_title: this.task_title,
                        is_teamwork: this.is_teamwork,
                        haul_distance: this.haul_distance,
                        remarks: this.messageInfo.remarks,
                    })
                    .then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success',
                        });
                        this.messageBox = false;
                        this.gitTaskListData();
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
        // 获取任务列表数据
        gitTaskListData() {
            this.loading = true;
            this.tableData = [];
            const _that = this;
            let new_time = this.timeSlot;
            if (!new_time) {
                new_time = ['', ''];
            }
            this.$axios
                .post('/interfaceApi/production/producttask/page', {
                    customer_name: this.company,
                    project_name: this.engineering,
                    place_info: this.position,
                    strength_info: this.grade,
                    pouring_name: this.mode,
                    sale_area: this.salesArea,
                    sale_method: this.salesMethods,
                    start_time: new_time[0],
                    end_time: new_time[1],
                    pageindex: this.nowPage,
                    pagesize: this.everyPage,
                    station_id: this.stationId,
                })
                .then(res => {
                    _that.loading = false;
                    if (res.rows.length > 0) {
                        // 列表数据集合
                        _that.tableData = res.rows;
                        // 列表加载状态
                        // 默认选中任务列表第一条任务
                        _that.radio = this.radioColor.station ? this.radioColor : res.rows[0];
                        // 默认选中任务列表第一条任务的默认场站
                        _that.activeName = this.radioColor.station ? this.radioColor.station : res.rows[0].station;
                        // 默认选中列表第一条的默认场站，切换站点编码不会切换
                        _that.defaultStation = this.radioColor.station ? this.radioColor.station : res.rows[0].station;
                        // 当前选中站点编码，切换站点编码也会切换
                        _that.defaultStiteCode = this.radioColor.station ? this.radioColor.station : res.rows[0].station;
                        // 当前选中任务要求到货时间
                        // eslint-disable-next-line max-len
                        _that.currentTime = this.radioColor.station ? this.radioColor.arrival_time.split(' ')[0] : res.rows[0].arrival_time.split(' ')[0];
                        // 列表总条数
                        _that.total = res.total;
                        // 获取站点数据集合
                        const stations = this.radioColor.station ? this.radioColor.station : res.rows[0].station;
                        _that.gitSiteData(stations);
                        // 初始化当前选中任务颜色
                        _that.setTasksColor(_that.radio);
                        if (this.radioColor.station) {
                            const index = _.findIndex(this.tableData, d => {
                                return d.ptid === _that.radioColor.ptid;
                            });
                            setTimeout(() => {
                                // _that.gitSelectionData(data);
                                _that.$refs.ref_dispatch.setCurrentRow(_that.tableData[index]);
                                _that.$refs.ref_dispatch.toggleRowSelection(_that.tableData[index]);
                            }, 100);
                        }
                    } else {
                        _that.gitSiteData();
                        // 初始化当前选中任务颜色
                        _that.setTasksColor();
                    }

                })
                .catch(error => {
                    _that.$message.error(error.ErrorCode.Message);
                });
        },

        // 设置当前选中任务颜色
        setTasksColor(data) {
            this.selectColorData.forEach((item, index) => {
                if (data && data.block_color !== '') {
                    if (data.block_color === item.backgroundColor) {
                        // 当前颜色下标
                        this.colorIndex = index;
                        // 当前颜色
                        this.currentColor = item.backgroundColor;
                    }
                } else {
                    this.colorIndex = 0;
                    this.currentColor = '#55C072';
                }
            });
        },
        // 加载拆单数据
        loadsplitdata(task_number, status) {
            this.$axios
                .get(`/interfaceApi/production/producttask/loadsplitdata/${task_number}`)
                .then(res => {
                    this.messageInfo = res;
                    this.messageBox = true;
                    this.taskNumber = res.task_number;
                    this.taskFangLiang = res.quantity;
                    this.task_title = res.task_title;
                    if (this.clickStatus === 1) {
                        this.haul_distance = res.haul_distance;
                    } else {
                        this.haul_distance = 0;
                    }
                    if (res.quantity === 0) {
                        this.inputStatus = true;
                    }
                    this.inputSiteDara = [...this.siteData];
                    this.inputSiteDara.forEach((item, index) => {
                        if (res.station === item.mixstation_code) {
                            this.inputSiteDara.splice(index, 1);
                        }
                    });
                    if (status === 1) {
                        this.onlyReadyStation = res.station_name_extend;
                        this.rwfl = res.quantity_extend;
                        this.value3 = this.arrival_time_extend;

                        this.splitQuantity = res.split_quantity - res.quantity_extend;
                    }
                    this.value2 = {
                        mixstation_name: res.station_name_extend,
                        mixstation_code: res.station_extend,
                    };
                    this.$forceUpdate();
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        // 获取站点数据集合
        gitSiteData(station) {
            const CompanyId = this.$takeTokenParameters('CompanyId');
            const BranchId = this.$takeTokenParameters('BranchId');
            let urlStr = '';
            if (CompanyId) {
                urlStr = '?companyId=' + CompanyId;
            } else {
                urlStr = '?branchId=' + BranchId;
            }
            this.$axios
                // .get('/interfaceApi/production/mixstation/mixstation')
                // .get('/interfaceApi/baseinfo/stationmanger/get_station_user/?userId=' + this.$takeTokenParameters('Uid'))
                .get('/interfaceApi/baseinfo/stationmanger/get_station_list/' + urlStr)
                .then(res => {
                    if (res.length > 0) {
                        // 厂站数据集合赋值
                        // this.siteData = res;
                        this.siteData = [];
                        res.forEach(item => {
                            this.siteData.push({
                                mixstation_code: item.station_id,
                                mixstation_name: item.name,
                                capacity: item.capacity,
                            });
                        });
                        // 当前站点产能
                        this.siteData.forEach(item => {
                            if (item.mixstation_code === station) {
                                this.capacity = item.capacity / 20;
                            }
                        });
                        // 获取默认场站已选择块
                        if (station) {
                            this.gitSelectionData();
                        }

                    }
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },

        // 获取计划调度表格数据集合
        gitplanData(data) {
            const _that = this;
            this.$axios
                .get('./../../planData.json')
                .then(res => {
                    _that.planData = res;
                    if (data && data.length > 0) {
                        // 清空当前任务已保存块
                        _that.currentTaskPreservation = [];
                        // 渲染已选择块
                        data.forEach(item => {
                            _that.planData[item.block_timesq * 2].data[item.block_position] = item;
                            // 判断所有已保存选择块中，是当前任务的块
                            if (item.task_number === this.radio.task_number) {
                            // 赋值当前任务已保存块
                                _that.currentTaskPreservation.push(item);
                            }
                        });
                        // 当前任务选择方量
                        _that.selectSquareQuantity = this.radio.alloc_quantity;
                    } else {
                        _that.selectSquareQuantity = 0;
                        _that.currentTaskPreservation = [];
                    }
                    // 数据更新强制刷新页面
                    _that.$forceUpdate();
                });
        },
        // 选择任务-单选
        getTemplateRow(row) {
            this.$refs.ref_dispatch.clearSelection();
            this.$refs.ref_dispatch.toggleRowSelection(row);
            this.radioColor = row;
            this.radio = row;
            this.isMask = false;
            this.selectedList = [];
            this.currentTaskPreservation = [];
            // 当前选中场站赋值
            this.activeName = row.station;
            // 当前选中任务默认站点
            this.defaultStation = row.station;
            // 当前选中站点编码，切换站点编码也会切换
            this.defaultStiteCode = row.station;
            this.gitSiteData(this.defaultStiteCode);
            // 当前选中任务要求到货时间
            this.currentTime = row.arrival_time.split(' ')[0];
            // 初始化当前选中任务颜色
            this.setTasksColor(row);
            // 获取当前任务已选择块
            this.gitSelectionData();
        },

        // 删除以保存的块
        deleteSelectionData(data, type) {
            this.delectList.push(data);
            if (type === 'delete') {
                // 当前任务选择方量
                this.selectSquareQuantity = this.formatNumber(
                    (this.currentTaskPreservation.length - this.delectList.length) * this.capacity
                );
            }
            // if (e.target.style.backgroundColor !== ('#f0f0f0' || 'rgb(240, 240, 240)')) {
            //     // e.target.style.background = '#f0f0f0';
            //     e.target.removeAttribute('style');
            // } else {
            this.confirmSelection('delete', data);
            // }
        },

        // 保存当前选择颜色
        setUpClass(data) {
            this.$axios
                .put('/interfaceApi/production/producttask/blockcolor', {
                    task_number: this.radio.task_number,
                    block_color: this.currentColor,
                })
                .then(() => {
                    // 刷新任务列表
                    this.gitTaskListData(data);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 确认选择块
        confirmSelection(type, data) {
            const _that = this;
            if (this.selectedList.length > 0 || this.delectList.length > 0) {
                this.$axios
                    .post('/interfaceApi/production/producttask/addblocks2', {
                    // 添加块集合
                        adds: this.selectedList,
                        // 删除块集合
                        deletes: this.delectList,
                    })
                    .then(() => {
                        _that.$message({
                            message: '操作成功！',
                            type: 'success',
                        });
                        // 判断如果是删除或者当前任务已经有选择块，直接刷新任务列表,否则需要先保存颜色
                        if (type === 'delete' || this.currentTaskPreservation.length ) {
                            // 刷新任务列表
                            this.gitTaskListData(data);
                        } else {
                            // 保存当前任务选择颜色
                            _that.setUpClass('1');
                        }
                        if (this.currentTaskPreservation.length === 0) {
                            this.radio.block_color = '';
                        }
                        _that.selectedList = [];
                        _that.delectList = [];
                    })
                    .catch(error => {
                        _that.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message({
                    message: '请先选择块！',
                    type: 'warning',
                });
            }
        },

        // 获取选择块
        gitSelectionData() {
            const _that = this;
            this.$axios
                .get('/interfaceApi/production/producttask/blocks?plan_date=' + this.currentTime + '&station=' + this.defaultStiteCode)
                .then(res => {
                    if (res && res[0]) {
                        this.currentColor = res[0].block_color;
                    }
                    // 初始化计划调度表格数据集合
                    _that.gitplanData(res);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 重置当前任务已选择块
        resetSelectionData() {
            if (this.currentTaskPreservation.length > 0) {
                this.selectedList = [];
                const _that = this;
                this.$axios
                    .post('/interfaceApi/production/producttask/resetblocks', {
                        task_number: this.radio.task_number,
                        plan_date: this.radio.plan_date,
                        station: this.defaultStiteCode,
                    })
                    .then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success',
                        });
                        this.currentTaskPreservation.forEach(item => {
                            _that.planData[item.block_timesq * 2].data[item.block_position].block_color = '';
                        });
                        this.currentTaskPreservation = [];
                        // 刷新任务列表
                        this.gitTaskListData();
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else if (this.selectedList.length > 0) {
                this.selectedList.forEach(item => {
                    this.planData[item.block_timesq * 2].data[item.block_position].block_color = '';
                });
                this.selectedList = [];
                this.currentTaskPreservation = [];
                // 当前任务选择方量
                this.selectSquareQuantity = 0;
            } else {
                this.$message({
                    message: '当前任务没有已保存块！',
                    type: 'warning',
                });
            }
        },

        // 任务下发
        taskAssignment(row) {
            this.$axios
                .put('/interfaceApi/production/producttask/distributed/' + row.task_number)
                .then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success',
                    });
                    // 刷新列表
                    this.gitTaskListData();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 打开查看任务详情弹出层
        btnClickfunc(row) {
            this.$YWShowLayer(`FormCode=PT202001142239040232&PKValues=${row.ptid}&IsView=true`);
        },
        // 点击订单编号弹出
        orderNumberClick(row) {
            if (row.distributed_status === 1) {
                this.$YWShowLayer(`FormCode=PO202001140931270228&subject=1&PKValues=${row.order_id}&IsView=true`);
            } else {
                this.$axios
                    .post('/interfaceApi/sale/contract_manager/is_contract_close_by_number/' + row.contract_number)
                    .then(res => {
                        if (!res) {
                            this.$YWShowLayer('FormCode=Form202007222132390323&PKValues=' + row.order_id);
                        } else {
                            this.$message({
                                message: '合同已关闭，不能复制新增!',
                                type: 'warning',
                            });
                        }
                    });
            }
        },
        // 格式化小数，处理当前已选方量保存两位小数
        formatNumber(value) {
            const valueStr = value.toString();
            if (valueStr.indexOf('.') !== -1 && valueStr.split('.')[1].length > 2) {
                return value.toFixed(2);
            }
            return value;
        },
        // 获取销售方式下拉数据
        getSalesMethodsList() {
            this.$axios
                .get('/interfaceApi/sale/contract_manager/get_dictionary?groupCode=sales_methods')
                .then(res => {
                    if ((res || []).length > 0) {
                        this.salesMethodsList.push(...res);
                    }
                });
        },
        // 获取销售区域下拉数据
        getSalesAreaDatas() {
            this.$axios
                .get('/interfaceApi/sale/price_config/get_area_list')
                .then(res => {
                    this.salesAreaDatas = res;
                });
        },
        // 选择发货单抬头
        chooseBillRemark() {
            this.$toast({
                title: true,
                type: 'eject',
                width: '13.4rem',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'settlement_company_list',
                        sureCallBack: this.chooseBillRemarkBack,
                    },
                },
            });
        },
        // 选择发货单抬头 回调
        chooseBillRemarkBack(data) {
            this.task_title = data.org_name;
        },

        // 缓存列表列宽度
        colChange() {
            const dispatchTableColWidths = {};
            if (this.$refs.ref_dispatch.columns) {
                this.$refs.ref_dispatch.columns.forEach(col => {
                    dispatchTableColWidths[col.label] = col.width;
                });
                dispatchTableColWidths.remarks = '生产发货-生产计划调度列表缓存列宽。';
                localStorage.setItem('dispatchTableColWidths', JSON.stringify(dispatchTableColWidths));
            }
        },
        // 获取带默认时间的日期
        getDateRange(arr) {
            const startStr = utils.timeFormat(arr[0], 'yyyy/MM/dd') + ' ' + this.defaultTime[0];
            const endStr = utils.timeFormat(arr[1], 'yyyy/MM/dd') + ' ' + this.defaultTime[1];
            return [new Date(startStr), new Date(endStr)];
        },

        // 新增订单
        newOrder() {
            this.$toast({
                title: true,
                text: '订单申请',
                type: 'eject',
                width: '80%',
                t_url: 'productionManagement/orderManagement/orderApplication',
                extr: {
                    type: 'newOrder',
                    pageName: 'dispatch',
                },
            });
        },
        // 后端导出数据
        exportData() {
            this.loading = true;
            const data = {
                customer_name: this.company,
                project_name: this.engineering,
                place_info: this.position,
                strength_info: this.grade,
                pouring_name: this.mode,
                sale_area: this.salesArea,
                sale_method: this.salesMethods,
                start_time: this.timeSlot && this.timeSlot[0] ? this.timeSlot[0] : '',
                end_time: this.timeSlot && this.timeSlot[1] ? this.timeSlot[1] : '',
                pageindex: this.nowPage,
                pagesize: this.everyPage,
                station_id: this.stationId,
            };
            this.$axios
                .post('/interfaceApi/production/producttask/export_plan', data, { responseType: 'arraybuffer' })
                .then(res => {
                    this.loading = false;
                    const blob = new Blob([res.data], { type: 'application/octet-stream' });
                    let fileName = res.headers['content-disposition'].split('filename*=UTF-8\'\'')[1];
                    fileName = decodeURIComponent(fileName);
                    if ('download' in document.createElement('a')) { // 非IE下载
                        const elink = document.createElement('a');
                        elink.download = fileName;
                        elink.style.display = 'none';
                        elink.href = URL.createObjectURL(blob);
                        document.body.appendChild(elink);
                        elink.click();
                        URL.revokeObjectURL(elink.href); // 释放URL 对象
                        document.body.removeChild(elink);
                    } else { // IE10+下载
                        navigator.msSaveBlob(blob, fileName);
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    beforeDestroy() {
        // 清除事件总线eventBus， 不手动清除，它是一直会存在的，就会反复进入
        eventBus.$off('gitTaskListUpdate');
    },
    created() {
        this.dispatchTableColWidths = JSON.parse(localStorage.getItem('dispatchTableColWidths')) || [];

        // 时间范围赋值
        const start = this.getDateStr(-3) + ' ' + this.defaultTime[0];
        const end = this.getDateStr(+2) + ' ' + this.defaultTime[1];
        this.timeSlot = [start, end];
        // 获取销售方式下拉数据
        this.getSalesMethodsList();
        // 获取销售区域下拉数据
        this.getSalesAreaDatas();

        // 获取任务列表数据
        this.gitTaskListData();

        // 获取计划调度表格数据集合
        this.gitplanData();
    },
    mounted() {
        eventBus.$on('gitTaskListUpdate', () => {
            setTimeout(() => {
                this.gitTaskListData();
            }, 1000);
        });
    },
};
</script>
<style lang="stylus" scope>
body
    -moz-user-select none
    -webkit-user-select none
    user-select none
.block_color_box
    width 100%
    height 100%
    display flex
    justify-content: center;
    align-items: center;
    .block_color
        color #ffffff
        width .28rem
        height .28rem
        font-size .16rem
        display flex
        justify-content: center;
        align-items: center;
.distributed_status
    width .6rem
    height .3rem
    display block
    background #edf8f1
    border 1px solid #d3eadd
    transform skew(-10deg)
.selectColor
    display flex
    align-items: center;
    .activeColor
        width: .2rem;
        height: .2rem;
        margin-right: .1rem;
.select-color
    display flex
    border 1px solid #D8D5D6
    font-size .16rem
    justify-content center
    align-items center
    padding: 0 .05rem;
    height .35rem
    p
        color #333333 !important
button
    cursor:pointer
.el-table__body tr.current-row>td{
  background-color: #ecf2f8 !important;
}
// 批量发送弹窗
.batch-sending-alert
    background rgba(0, 0, 0, 0.5);
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    z-index 7
    display flex
    justify-content center
    align-items center
    .alert-content
        background #fff
        width: 10rem;
        height 6.5rem;
        position relative
    .header-info
        display flex
        justify-content space-between
        align-items center
        height .6rem
        background #5368a8
        text-align center
        color #fff
        padding 0 .2rem
        h1
            font-size .26rem
        .close-alert
            cursor:pointer
            transform:rotate(45deg)
            font-size .3rem
    .batch-sending-btn
        position: absolute;
        bottom: .2rem;
        left: 50%;
        transform: translateX(-50%);
        .el-button
            width 2rem
            height .4rem
            margin-right .4rem
// 拆单/维护弹窗
.maintain-or-order
    background rgba(0, 0, 0, 0.5);
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    z-index 7
    display flex
    justify-content center
    align-items center
.order-info
    width: 13.5rem;
    height 7rem
    background #fff
    position relative
    .header-info
        display flex
        justify-content space-between
        align-items center
        height .6rem
        background #414a61
        text-align center
        color #fff
        padding 0 .2rem
        h1
            font-size .26rem
        .close-alert
            cursor:pointer
            transform:rotate(45deg)
            font-size .3rem
    .detailed-info
        width: 100%;
        height calc(100% - 1.4rem)
        padding: .2rem;
        .setBorder
            display: flex;
            flex-wrap: wrap;
            table
                width 100%
                border-collapse: collapse;
                th,td
                    border 1px solid #ccc
                th
                    min-width: 2.4rem;
                    height: .36rem;
                    line-height: .36rem;
                    background: #f7f8fc;
                    font-size .16rem
                    color #606266
                    font-weight 400
                    span
                        color red
                td
                    input
                        width 100% !important
                        height 100% !important
                        border none
                        font-size .16rem
                    .el-input
                        width 100% !important
                        height 100% !important
                        border none
                        font-size .16rem
                    .el-input--prefix .el-input__inner
                        padding-left: .22rem;
                    .el-input__prefix
                        display none
                    input::-webkit-input-placeholder
                        color: #C0C0C0;
                        font-size: .16rem
                    .el-radio-group
                        border-left 1px solid #ccc
                        padding-left 15px
                        .el-radio
                            line-height 0.36rem
                    .el-select
                        .el-input
                            .el-select__caret
                                line-height 0.2rem
    .beizhuStyle
        border-right none !important
    .bottom-btn
        width 100%
        display flex
        justify-content center
        align-items center
        button
            font-size: 0.16rem !important;
            width: 2.6rem;
            height: 0.5rem;
            font: 0.2rem '微软雅黑';
            margin: 0 0.15rem;
            border: none;
            cursor: pointer;

.dispatch
    width 100%
    height 100%
    overflow-y auto
    overflow-x hidden
    .searchBox
        border-bottom 5px solid #e7e8eb
        padding-bottom .1rem
    .searchArea
        height 0.6rem
        padding 0.12rem 0.2rem
        background #ffffff
        display flex
        &:nth-of-type(2)
            height 0.48rem
            padding-top 0
        >div
            display flex
            margin-right 0.2rem
            &:last-child
                float right
                margin-right 0
            >span
                line-height 0.36rem
                white-space nowrap
            .el-date-editor
                width 3.8rem !important
            .el-input
                height 0.36rem
                input
                    height 0.36rem
                    line-height 0.36rem
            .el-button
                width 1rem
                height 0.36rem
                color #fff
                padding 0
            .el-input__inner
                height 0.36rem
            .el-date-editor
                .el-range-separator
                    width auto
                    line-height 0.28rem
                .el-range__icon
                    line-height 0.28rem
                    font-size 0.14rem
    .contntBox
        width 100%
        height calc(100% - 2.1rem)
        position relative
        .head_btn
            position relative
            margin .2rem .2rem .1rem .2rem
            .header_set_btn
                position absolute
                top 0
                right 0
        .table
            padding 0.1rem 0.2rem
            height 100%
            margin-bottom 0.2rem
            padding-top 0
            background #fff
            .table-bg
                height calc(100% - 0.42rem)
        .schedule
            width 100%
            height 3.5rem
            background #fff
            position absolute
            bottom 0
            left 0
            box-shadow 0px -2px 8px 0px rgba(85, 136, 241, 0.35)
            border-radius 2px 4px 0 0
            z-index 99
            .tabs
                height 0.4rem
                margin-top -.3rem
                border-bottom 3px solid #45AAF5
                border-image: linear-gradient(to right, #45aaf5, #2bc6f1) 1;
                position relative
                .tab_box
                    display flex
                    height 100%
                    margin-top: 0.1rem;
                    .tab_item
                        padding .02rem .06rem
                        margin 0 .12rem
                        font-size .14rem
                        background #F5F5F5
                        strong
                            color #828182
                    .active_tab
                        background #45AAF5
                        color #ffffff
                        strong
                            color #ffffff
                .tab_right
                    position absolute
                    top 0
                    right .1rem
                    display flex
                    font-size .14rem
                    height 100%
                    .tip
                        padding 0 .2rem
                        color #ffffff
                        position relative
                        margin-right .15rem
                        height 81%
                        margin-top .06rem
                        background url(./images/hsjx.png) no-repeat
                        background-size 100% 100%
                        margin-right -.1rem
                    .tip_no
                        margin-right -.06rem !important
                    .take
                        background url(./images/lsjx.png) no-repeat
                        background-size 100% 100%
                        color #ffffff
                        text-align center
                        padding .05rem .2rem 0 .2rem
                        margin-right -.15rem
                        i
                            font-size 16px
                            color #f4ff00
                            font-weight 800
                            -webkit-text-stroke 1.3px #FF8B02
                            margin 0 .03rem
                    .take_no
                        background url(./images/lsjx.png) no-repeat
                        background-size 100% 100%
                        color #ffffff
                        text-align center
                        padding .05rem .2rem 0 .2rem
                        margin-right -.1rem
                        i
                            font-size 16px
                            color #f4ff00
                            font-weight 800
                            -webkit-text-stroke 1.3px #FF8B02
                            margin 0 .03rem
                    .get_back
                        width .7rem
                        background url(./images/tx.png) no-repeat
                        background-size 100% 100%
                        z-index 99
                        text-align center
                        color #ffffff
                        cursor pointer
                        .back_box
                            display flex
                            flex-direction column
                .el-tabs__header
                    margin-bottom 0
                    .el-tabs__nav-wrap
                        padding 0 0.4rem
                        .el-tabs__nav
                            .el-tabs__item
                                height 0.53rem
                                line-height 0.53rem
                                &:hover,
                                    span,
                                    strong
                                        color #D6000F
                                &.is-active
                                    span,
                                    strong
                                        color #D6000F
                                span
                                    display block
                                    height 0.53rem
                                    padding 0 0.2rem
                                    font-size 0.16rem
                                    font-weight 600
                                    color #333
                                    position relative
                                    &.defaultStation
                                        color #5588F1
                                        strong
                                            color #5588F1
                                        i
                                            display block
                                    strong
                                        font-weight 400
                                        color #979EA7
                                    i
                                        width 0.44rem
                                        height 0.22rem
                                        background #5588F1
                                        border-radius 2px
                                        font-size 0.12rem
                                        line-height 0.22rem
                                        color #fff
                                        text-align center
                                        position absolute
                                        top 0px
                                        right 0
                                        background url(./../../assets/images/productionManagement/icon.png) no-repeat
                                        background-size 100%
                                        display none
                .el-tabs__active-bar
                    background-color #D6000F
            .colorBlock
                height calc(100% - 0.53rem)
                position relative
                overflow hidden
                .mask
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                    background rgba(0,0,0,0.2)
                    z-index 9
                .clean-color
                    span
                        width 0.35rem !important
                        height 0.25rem !important
                        border 1px solid #D7D7D7
                        margin 0.07rem 0.1rem
                        position relative
                        cursor pointer
                        &:after
                            content ''
                            width: 0.3rem !important;
                            height: 0.2rem !important;
                            position absolute
                            border 1px solid #ffffff
                            top 50%
                            left 50%
                            transform translate(-50%, -50%)
                            display none
                .condition
                    height 0.3rem
                    margin 0.2rem 0.2rem 0.1rem
                    span,p,strong
                        float left
                    .selectColor
                        p
                            font-size 0.16rem
                            // color #979EA7
                            line-height 0.3rem
                        span
                            // width 0.26rem
                            // height 0.16rem
                            // border 1px solid #D7D7D7
                            margin 0.04rem 0.07rem
                            position relative
                            cursor pointer
                            &:after
                                content ''
                                width 0.24rem
                                height 0.14rem
                                position absolute
                                border 1px solid #ffffff
                                top 50%
                                left 50%
                                transform translate(-50%, -50%)
                                display none
                            &:before
                                font-size 0.16rem
                                position absolute
                                top 50%
                                left 50%
                                transform translate(-50%, -50%)
                                color #fff
                                display none
                            &.active
                                &:after,
                                &:before
                                    display block

                        button
                            width 0.65rem
                            height 0.33rem
                            border-radius 3px
                            font-size 0.14rem
                            border 1px solid #0078FB
                            margin 0.03rem 0
                            cursor pointer
                            &.Determine
                                background #0078FB
                                color #fff
                                margin-left .1rem
                            &.Reset
                                background #fff
                                margin-left .1rem
                                border 1px solid #D9D9D9 !important
                        strong
                            font-size 0.14rem
                            color red
                            line-height 0.3rem
                            float right
                            margin-left 0.1rem
                            &:nth-of-type(2)
                                margin-right 0.2rem
                                color #000
                                i
                                    font-style none
                                    color #23e535
                                    padding 0.05rem
                                    font-style 0.2rem
                        .color_box
                            display flxe
                            font-size 12px
                            height .35rem
                            border 1px solid #D9D9D9
                            margin 0 .1rem
                            cursor pointer
                            &.active
                                background #E3ECF2
                            span
                                display flex
                                justify-content center
                                align-items center
                    .selectTime
                        p
                            font-size 0.16rem
                            color #979EA7
                            line-height 0.3rem
                        span
                            font-size 0.16rem
                            line-height 0.3rem
                            color #0078FB
                            font-weight 400
                            background url(./images/rqbj.png) no-repeat
                            background-size 100% 100%
                            padding 0 .2rem 0 .35rem
                            strong
                                margin-left .1rem
                                float right
                                font-weight 400
                        button
                            width 0.6rem
                            height 0.3rem
                            border none
                            margin-left 0.1rem
                            cursor pointer
                            font-size 0.16rem
                            &:nth-of-type(1),
                            &:nth-of-type(2),
                            &:nth-of-type(3)
                                background rgba(21, 119, 210, 0.1)
                                color rgba(21, 119, 210, 1)
                            // &:nth-of-type(2)
                            //     background rgba(235, 101, 111, 0.1)
                            //     color rgba(235, 101, 111, 1)
                .planTable
                    width 100%
                    height calc(100% - 0.5rem)
                    padding 0.2rem
                    padding-top 0
                    table
                        width 100%
                        border-collapse collapse
                        table-layout fixed
                        word-break break-all
                        th,td
                            text-align center
                            border 1px solid #D7D7D7
                            font-size 0.14rem
                        table
                            th,td
                                height 0.2rem
                                border none
                            th
                                width calc(100%/48)
                                color #333333
                                font-weight 400
                            td
                                border-top 1px solid #D7D7D7
                                span
                                    display block
                                    width 100%
                                    height 100%
                                    background #F0F0F0
                    #moveSelected
                        position fixed
                        background-color blue
                        opacity 0.3
                        border 1px dashed #d9d9d9
                        top 0
                        left 0
        .left_btn
            width 1rem
            height 0.4rem
            background url(./images/production1.png) no-repeat
            background-size 100% 100%
            position absolute
            left calc(50% - .26rem)
            bottom -.66rem
            z-index 99
            cursor pointer
        .right_btn
            width 0.52rem
            height 0.8rem
            background url(./images/back.png) no-repeat
            background-size 100% 100%
            position absolute
            right -0.02rem
            bottom 2.75rem
            z-index 99
            cursor pointer
</style>
